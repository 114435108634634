import TileLayer from 'ol/layer/Tile';
import TileWMS from 'ol/source/TileWMS';
import WMTS from 'ol/source/WMTS';
import { createTileGridTRE } from './MapFunctions';

const virastokarttaMV = new TileLayer({
  visible: true,
  properties: {
    title: 'Virastokartta',
    name: 'Virastokartta',
    id: 'vk-tre',
    type: 'base',
    theme: 'light',
  },
  source: new WMTS({
    url: 'https://georaster.tampere.fi/geoserver/gwc/service/wmts?service=WMTS',
    layer: 'georaster:virastokartta_mv_EPSG_3067',
    matrixSet: 'JHS',
    format: 'image/png',
    projection: 'EPSG:3067',
    style: '',
    tileGrid: createTileGridTRE(256),
  }),
});

const auratWMS = (layer: string) =>
  new TileLayer({
    source: new TileWMS({
      url: 'https://map01.fluentprogress.fi/cgi-bin/kuntowmscurrentviewdata?',
      params: {
        LAYERS: layer,
        VERSION: '1.1.1',
        STYLES: '',
        WIDTH: 256,
        HEIGHT: 256,
      },
    }),
    properties: {
      id: layer,
    },
    visible: true,
    zIndex: 101,
  });

export { auratWMS, virastokarttaMV };
